.word-checkbox {
    display: flex;
    justify-content: center;
    margin: 0.25rem 0 0.5rem 0;
}

.word-checkbox-input {
    width: 1.25rem;
    height: 1.25rem;
    justify-content: center;
    background-color: var(--light-grey);
}

.word-checkbox-input:hover {
    border-color: var(--dark-orange);
}
