:root {
    --dark-blue: #2d7592;
    --light-blue: #b5dae8;
    --dark-aqua: #16929e;
    --light-aqua: #83ccc0;
    --dark-orange: #f3783d;
    --light-orange: #fbd0bc;
    --dark-red: #cf443a;
    --light-green: #bbca76;
    --light-grey: #f0f0f0;

    --gilroy-font-family: 'Gilroy W00 Medium';
    --boucherie-font-family: 'Boucherie Cursive W05 Bold';

    --header-height: 60px;
}

body {
    font-family: var(--gilroy-font-family);
    font-size: 110%;
}

body.scrollbar-disabled {
    /* scrollbar-gutter: stable; *** doesn't seem to work */
    -ms-overflow-style: none; /* IE, Edge */
    scrollbar-width: none; /* Firefox */
}

body.scrollbar-disabled::-webkit-scrollbar {
    /* Chrome, Safari, Opera */
    display: none;
}

.hidden-element {
    visibility: hidden;
}

.navbar {
    padding: 0;
}

.navbar a {
    color: var(--dark-blue);
    text-transform: uppercase;
}

.navbar-brand {
    padding: 0.5rem 0 0.5rem 0;
}

.nav-link {
    color: var(--dark-blue);
    text-align: right;
    cursor: pointer;
}

.nav-link:hover {
    color: var(--dark-orange);
}

.nav-pills .nav-link.active {
    background-color: var(--dark-blue);
}

.nav-item {
    color: var(--dark-blue);
    text-align: right;
    text-transform: uppercase;
    padding: 0.5rem;
    cursor: pointer;
}

.nav-item:hover {
    color: var(--dark-orange);
}

.nav-item.dropdown {
    padding: 0;
}

.navbar-collapse {
    padding: 0 2% 0 5%;
}

.navbar-nav {
    margin-left: auto;
}

.navbar .dropdown-toggle::after {
    display: none;
}

.btn-primary,
.btn-primary:focus,
.btn-primary:checked,
.btn-primary:active,
.btn-primary:active:focus {
    color: white;
    background-color: var(--dark-blue);
    border-style: solid;
    border-color: var(--dark-blue) !important;
    border-radius: 0.25em !important;
    box-shadow: none !important;
}

.btn-primary:hover {
    background-color: var(--dark-blue) !important;
    border-color: var(--dark-orange) !important;
}

.btn-primary:disabled {
    background-color: var(--dark-blue);
    opacity: 0.65;
}

.btn-outline-primary {
    color: var(--dark-blue);
    background-color: inherit;
    border-style: solid;
    border-color: var(--dark-blue) !important;
    border-radius: 0.25em !important;
    box-shadow: none !important;
}

.btn-outline-primary:hover {
    color: white;
    background-color: var(--dark-blue);
    border-color: var(--dark-orange) !important;
}

.btn-outline-primary:focus,
.btn-outline-primary:checked,
.btn-outline-primary:active,
.btn-outline-primary:active:focus {
    color: white;
    background-color: var(--dark-blue);
}

.modal-word-card {
    background-color: var(--light-grey);
    border: solid thin var(--dark-blue);
}

.modal-word-card-image {
    display: block;
    margin: 0.5em;
    width: 100%;
    min-width: 100%;
}

.form-select {
    border-color: var(--dark-blue);
}

.form-select:focus {
    border-color: var(--dark-blue);
    box-shadow: none;
}

.form-select > option:checked,
.form-select:focus > option:checked {
    color: black;
    background-color: var(--light-blue);
}

.form-select > option:hover,
.form-select:focus > option:hover {
    color: var(--light-orange);
    background-color: var(--dark-blue);
}

.form-select > option:hover:checked,
.form-select:focus > option:hover:checked {
    color: white;
    background-color: var(--dark-blue);
}

.form-check-input {
    border-color: var(--dark-blue);
}

.form-check-input:focus {
    border-color: var(--dark-blue);
    box-shadow: none;
}

.form-check-input:checked {
    background-color: var(--dark-blue);
    border-color: var(--dark-blue);
}

.form-label {
    margin-bottom: 0.3rem;
}

.alert-dismissible .btn-close {
    scale: 0.8;
}

.navbar-container {
    display: flex;
    padding: 0 3rem 0 3rem;
    justify-content: space-between;
    border-bottom: solid thin lightgrey;
}

.page-footer {
    min-width: 100%;
    text-align: center;
    margin: 0;
    padding: 1.5rem 0 2rem 0;
    border-top: solid thin lightgrey;
}

.section-container {
    max-width: 100%;
    margin: 0;
    padding: 0 1rem 0 1rem;
}

.list-container {
    background-color: lightgrey;
    border-style: none;
    border-radius: 0.5em;
    padding: 2rem 3rem 2rem 3rem;
}

.page-description-setup-div {
    padding: 3rem 0.75rem 3rem 0.75rem;
}

.vertical-line-column {
    border-right: solid thin lightgrey;
}

.activity-page-div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0.5rem 2rem 5rem 2rem;
}

.activity-board-container {
    background-color: lightgrey;
    border-style: none;
    border-radius: 0.5rem;
    padding: 1rem;
}

.activity-control-container {
    width: 5%;
    min-width: 2.5rem;
    justify-content: center;
    margin: 2rem 0 0 0;
    padding: 0;
}

.text-entry-label {
    text-transform: uppercase;
    color: var(--dark-blue);
    padding-top: 0.3rem;
}

.text-entry-box {
    height: auto;
    width: 30%;
    min-width: 20rem;
    font-size: 14pt;
    border: solid thin var(--dark-blue);
    padding: 10px;
}

.text-entry-box:focus {
    border: solid thin var(--dark-orange);
    box-shadow: none;
}

.text-link {
    color: var(--dark-aqua);
    text-decoration: none;
}

.text-link:hover {
    color: var(--light-aqua);
}
