.search-param-select-button-group {
    margin: 0.1rem 3rem 1rem 0;
}

.search-param-select-label {
    color: var(--dark-blue);
    text-transform: uppercase;
    text-align: center;
    height: 2.5rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    padding-top: 0.5rem;
}

.search-param-select-box {
    background-color: white;
    min-height: 6.5rem;
    border-style: solid;
    border-width: 0.15rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.search-param {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.search-param-selected {
    color: black;
    background-color: var(--light-blue);
}

.search-param-selected:hover {
    color: white;
    background-color: var(--dark-blue);
    cursor: pointer;
}

.search-param-deselected {
    color: black;
    background-color: inherit;
}

.search-param-deselected:hover {
    color: var(--light-orange);
    background-color: var(--dark-blue);
    cursor: pointer;
}
