.activity-control-icon {
    min-width: 2rem;
    margin: 0 0 2rem 0;
    border-radius: 0.25em;
}

.activity-control-icon:hover {
    cursor: pointer;
}

.activity-control-dropdown-icon-menu {
    width: auto;
    min-width: max-content;
    text-align: start;
    border-radius: 0.25rem;
}
