.copycat-title {
    width: 100%;
    justify-content: center;
    text-align: center;
    color: white;
    background-color: var(--dark-aqua);
    font-size: 200%;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    margin: 0;
    padding: 0.5rem 0.5rem 0.3rem 0.5rem;
}

.copycat-board-container {
    width: 100%;
    background-color: lightgrey;
    text-align: center;
    padding: 1rem;
    border-style: solid;
    border-color: var(--dark-aqua);
    border-width: 0.25rem;
}

.copycat-card {
    display: flex;
    width: 100%;
    height: 100%;
    border: hidden;
}

.copycat-card-image-div {
    display: flex;
    aspect-ratio: 1/1;
    width: 100%;
    height: 100%;
    align-items: center;
    overflow: hidden;
    border: solid thin grey;
    border-radius: 0.25rem;
}

.copycat-card-image-normal-div {
    background-color: white;
    border: solid thin grey;
    border-radius: 0.25rem;
}

.copycat-card-image-playing-div,
.copycat-card-image-click-success-div {
    background-color: var(--light-aqua);
    border: solid thick var(--dark-aqua);
    border-radius: 0.25rem;
}

.copycat-card-image-click-failure-div {
    background-color: var(--light-orange);
    border: solid thick var(--dark-orange);
    border-radius: 0.25rem;
}

.copycat-card-image {
    display: block;
    align-items: center;
    overflow: hidden;
    width: 85%;
    margin: 0;
    padding-top: 5%;
}

.copycat-card-text {
    position: absolute;
    bottom: 4%;
    width: 100%;
    font-size: 130%;
}

.copycat-control-div {
    min-height: 6rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}
