.memory-match-title {
    width: 100%;
    justify-content: center;
    text-align: center;
    color: white;
    background-color: var(--dark-aqua);
    font-size: 200%;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    margin: 0;
    padding: 0.5rem 0.5rem 0.3rem 0.5rem;
}

.memory-match-board-container {
    width: 100%;
    background-color: lightgrey;
    text-align: center;
    padding: 1rem;
    border-style: solid;
    border-color: var(--dark-aqua);
    border-width: 0.25rem;
}

.memory-match-card {
    display: flex;
    width: 100%;
    height: 100%;
    border: hidden;
}

.memory-match-word-card-image-div {
    display: flex;
    aspect-ratio: 1/1;
    width: 100%;
    height: 100%;
    align-items: center;
    overflow: hidden;
    border: solid thin grey;
    border-radius: 0.25rem;
}

.memory-match-word-card-image {
    display: block;
    align-items: center;
    overflow: hidden;
    width: 85%;
    margin: 0;
    padding-top: 5%;
}

.memory-match-word-card-text {
    position: absolute;
    bottom: 4%;
    width: 100%;
    font-size: 130%;
}

.memory-match-shape-card-image-div {
    aspect-ratio: 1/1;
    width: 100%;
    height: 100%;
    align-items: center;
    border: hidden;
}

.memory-match-shape-card-image {
    display: flex;
    aspect-ratio: 1/1;
    align-items: center;
    overflow: hidden;
    scale: 1.0;
    background-color: lightgrey;
    border-width: 0;
    border-radius: 0.25rem;
}

.memory-match-result-div {
    height: 5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.memory-match-modal-image-div {
    position: relative;
    display: flex;
    aspect-ratio: 1/1;
    align-items: center;
    margin-top: 10%;
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    width: 95%;
    min-width: 75%;
}

.memory-match-modal-face-image {
    position: absolute;
    z-index: 1;
}

.memory-match-modal-word-image {
    position: absolute;
    transform: translate(68%, 68%);
    scale: 0.4;
    z-index: 2;
    background-color: var(--light-grey);
    border: solid thin var(--dark-blue);
}
