.action-button,
.action-dropdown-toggle {
    max-height: 2.75rem;
    text-transform: uppercase;
    padding: 0.5rem 1.25rem;
}

.action-button-active,
.action-dropdown-toggle-active {
    color: white !important;
}

.action-button-active:hover,
.action-dropdown-toggle-active:hover {
    box-shadow: 0 0 0 0.1em var(--dark-orange);
}

.action-button-inactive,
.action-dropdown-toggle-inactive {
    color: var(--dark-blue) !important;
    background-color: inherit !important;
    opacity: 1 !important;
}

.danger-button {
    color: white;
    background-color: var(--dark-red);
    max-height: 2.75rem;
    text-transform: uppercase;
    padding: 0.5rem 1.25rem;
}

.danger-button-active {
    color: white !important;
    border-style: none;
}

.danger-button-inactive {
    color: var(--dark-red) !important;
    background-color: inherit !important;
    opacity: 1 !important;
    border-style: solid;
    border-color: var(--dark-red) !important;
}

.danger-button:hover,
.danger-button:focus {
    background-color: var(--dark-red) !important;
    box-shadow: 0 0 0 0.1rem var(--dark-blue) !important;
}
