.login-signup-label {
    width: 30rem;
    text-transform: uppercase;
    color: var(--dark-blue);
    font-size: 14pt;
    padding-left: 2px;
}

.login-signup-view-box {
    height: auto;
    width: 100%;
    font-size: 13.5pt;
    border: solid thin var(--dark-blue);
    margin: 0;
    padding: 10px;
}

.login-signup-view-box:focus {
    border: solid thin var(--dark-orange);
    box-shadow: none;
}



.login-signup-area {
    border: solid thin grey;
    border-radius: 0.5em;
    padding-top: 2em;
    padding-bottom: 1em;
}

.login-signup-label {
    text-transform: uppercase;
    color: var(--dark-blue);
    font-size: 14pt;
    padding-left: 2px;
}

.login-signup-input-box {
    height: 2.5em;
    width: 100%;
    font-size: 13pt;
    border: solid thin var(--dark-blue);
    padding: 10px;
}

.login-signup-input-box:focus {
    border: solid thin var(--dark-orange);
    box-shadow: none;
}

.login-signup-submit-button {
    text-transform: uppercase;
    padding: 0.5em 1.25em;
}

.login-signup-submit-button-active {
    color: white !important;
    background-color: var(--dark-blue) !important;
}

.login-signup-submit-button-active:hover {
    box-shadow: 0 0 0 0.1em var(--dark-orange);
}

.login-signup-submit-button-inactive {
    color: var(--dark-blue) !important;
    background-color: inherit !important;
    opacity: 1 !important;
}
