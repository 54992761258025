.whimprovs-title {
    width: 100%;
    justify-content: center;
    text-align: center;
    color: white;
    background-color: var(--dark-orange);
    font-size: 210%;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    margin: 0;
    padding: 0.75rem 0.5rem 0.3rem 0.5rem;
}

.whimprovs-board-container {
    width: 100%;
    background-color: var(--light-grey);
    text-align: center;
    padding: 1rem;
    border-style: solid;
    border-color: var(--dark-orange);
    border-width: 0.25rem;
}

.whimprovs-choice-card-div {
    display: flex;
    justify-content: center;
    width: 80%;
    height: 80%;
    margin: 2rem auto 2rem auto;
    padding: auto;
    gap: 1.5rem;
}

.whimprovs-choice-word-card {
    max-width: 15rem;
}

.whimprovs-choice-word-card:hover {
    cursor: pointer;
}

.whimprovs-passage-container {
    width: 100%;
    background-color: var(--light-grey);
    font-size: 160%;
    border-style: none;
    padding: 1% 7% 0.25rem 7%;
}

.whimprovs-text-chunk {
    font-size: 120%;
    margin-top: auto;
    margin-bottom: 1.6rem;
    margin-right: 1rem;
}

.whimprovs-word-card {
    display: flex;
    width: 21%;
    height: 21%;
    border: solid thin grey;
    margin: 0.5rem 1rem 1.5rem 0;
}

.whimprovs-word-card-image-div {
    display: flex;
    aspect-ratio: 1/1;
    width: 100%;
    height: 100%;
    align-items: center;
    overflow: hidden;
}

.whimprovs-word-card-image {
    display: block;
    align-items: center;
    overflow: hidden;
    width: 75%;
    margin: 0;
    padding-top: 5%;
}

.whimprovs-word-card-text {
    position: absolute;
    bottom: 2.5%;
    width: 100%;
    font-size: 100%;
}

.whimprovs-fwd-rev-buttons-container {
    width: 75%;
    margin: 0 auto 2.5rem auto;
}

.whimprovs-fwd-rev-image {
    width: 12%;
    min-width: 3rem;
    max-width: 4rem;
    margin: 0.2rem auto 0.2rem auto;
    align-items: center;
    border-style: none;
}

.whimprovs-fwd-rev-image:hover {
    cursor: pointer;
}
