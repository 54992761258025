.list-select-button-group {
    min-width: 25rem;
    max-width: 40rem;
    margin: 0.1rem 3rem 1rem 0;
}

.list-select-label {
    color: var(--dark-blue);
    text-transform: uppercase;
    padding-left: 0.5rem;
}

.list-select-select {
    margin: 0;
}
