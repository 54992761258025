.pick-a-path-title {
    width: 100%;
    justify-content: center;
    text-align: center;
    color: white;
    background-color: var(--dark-orange);
    font-size: 210%;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    margin: 0;
    padding: 0.75rem 0.5rem 0.3rem 0.5rem;
}

.pick-a-path-vocab-container {
    width: 100%;
    background-color: var(--light-grey);
    text-align: center;
    padding: 1rem;
    border-style: solid;
    border-color: var(--dark-orange);
    border-width: 0.25rem;
}

.pick-a-path-vocab-fwd-rev-image {
    width: 40%;
    min-width: 3rem;
    align-items: center;
    border-style: none;
}

.pick-a-path-vocab-fwd-rev-image:hover {
    cursor: pointer;
}

.pick-a-path-text-container {
    width: 100%;
    background-color: var(--light-grey);
    border-style: solid;
    border-color: var(--dark-orange);
    border-width: 0.25rem;
}

.pick-a-path-easy-reader-text-container {
    font-size: 160%;
    padding: 3% 5% 0.75% 8%;
}

.pick-a-path-proficient-reader-text-container {
    font-size: 150%;
    padding: 2% 5% 2% 5%;
}

.pick-a-path-text-chunk {
    font-size: 120%;
    margin-right: 1rem;
}

.pick-a-path-easy-reader-text-chunk {
    margin-top: auto;
    margin-bottom: 1.6rem;
}

.pick-a-path-proficient-reader-text-chunk {
    line-height: 0.25rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.pick-a-path-word-card {
    display: flex;
    width: 21%;
    height: 21%;
    border: solid thin grey;
    margin: 0.5rem 1rem 1.5rem 0;
}

.pick-a-path-word-card-image-div {
    display: flex;
    aspect-ratio: 1/1;
    width: 100%;
    height: 100%;
    align-items: center;
    overflow: hidden;
}

.pick-a-path-word-card-image {
    display: block;
    align-items: center;
    overflow: hidden;
    width: 75%;
    margin: 0;
    padding-top: 5%;
}

.pick-a-path-word-card-text {
    position: absolute;
    bottom: 2.5%;
    width: 100%;
    font-size: 100%;
}

.pick-a-path-choices-header {
    width: 100%;
    justify-content: center;
    text-align: center;
    color: white;
    background-color: var(--dark-orange);
    font-size: 200%;
    margin: 0;
    padding: 0.3rem 0.5rem 0.3rem 0.5rem;
}

.pick-a-path-choices-container {
    width: 100%;
    background-color: var(--light-grey);
    font-size: 140%;
    border-style: solid;
    border-color: var(--dark-orange);
    border-width: 0.25rem;
    padding: 1% 0.5% 1% 0.5%;
}

.pick-a-path-choices-content-div {
    background-color: var(--light-grey);
    text-align: center;
    padding: 0 1% 0 1%;
}

.pick-a-path-choice-text-div {
    color: white;
    background-color: var(--dark-blue);
    width: 90%;
    font-size: 100%;
    padding: 1% 3% 1% 3%;
    border: solid thin var(--dark-blue);
    border-radius: 0.25rem;
}

.pick-a-path-choice-text-div:hover {
    border-color: var(--dark-orange);
    cursor: pointer;
}

.pick-a-path-choice-box {
    width: 100%;
    background-color: white;
    border: solid thin var(--dark-blue);
    border-radius: 0.25rem;
    margin: auto 2% auto 2%;
    padding: 1rem 0 1rem 0;
}

.pick-a-path-choice-box:hover {
    border-color: var(--dark-orange);
    cursor: pointer;
}

.pick-a-path-choice-box-text-chunk {
    font-size: 110%;
    margin-top: auto;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.pick-a-path-choice-box-card {
    display: flex;
    width: 32%;
    height: 32%;
    border: solid thin grey;
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
}

.pick-a-path-end-text-divider {
    width: 50%;
    height: 0%;
    margin: auto;
    border-bottom-style: solid;
    border-bottom-color: var(--dark-orange);
    border-bottom-width: 0.25rem;
}

.pick-a-path-end-footer {
    width: 100%;
    justify-content: center;
    text-align: center;
    color: white;
    background-color: var(--dark-orange);
    font-size: 200%;
    margin: 0;
    padding: 0.3rem 0.5rem 0.4rem 0.5rem;
}
