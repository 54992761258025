.word-card {
    display: flex;
    width: 100%;
    height: 100%;
    border: solid thin grey;
}

.word-card-image-div {
    display: flex;
    aspect-ratio: 1/1;
    width: 100%;
    height: 100%;
    align-items: center;
    overflow: hidden;
}

.word-card-image {
    display: block;
    align-items: center;
    overflow: hidden;
    width: 85%;
    margin: 0;
    padding-top: 5%;
}

.word-card-image-active {
    opacity: 1;
}

.word-card-image-inactive {
    opacity: 0.25;
}

.word-card-text {
    position: absolute;
    bottom: 4%;
    width: 100%;
    font-size: 130%;
}
