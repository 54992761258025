.select-all-checkbox {
    margin: 0.5rem 0 1rem 3rem;
}

.select-all-checkbox-input {
    width: 1.25rem;
    height: 1.25rem;
}

.select-all-checkbox-input-matching {
    background-color: var(--light-grey);
}

.select-all-checkbox-input-not-matching {
    background-color: var(--light-orange);
}

.select-all-checkbox-input:hover {
    border-color: var(--dark-orange);
}

.select-all-checkbox-label {
    margin-top: -0.05rem;
    margin-left: 0.5rem;
    font-size: 120%;
}

.select-all-checkbox-label:hover {
    color: var(--dark-blue);
}
