.profile-label {
    width: 30rem;
    text-transform: uppercase;
    color: var(--dark-blue);
    font-size: 14pt;
    padding-left: 2px;
}

.profile-view-box {
    height: auto;
    width: 100%;
    font-size: 13.5pt;
    border: solid thin var(--dark-blue);
    margin: 0;
    padding: 10px;
}

.profile-view-box:focus {
    border: solid thin var(--dark-orange);
    box-shadow: none;
}
