.list-operation-page-div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0.5rem 2rem 0rem 2rem;
}

.list-operation-control-container {
    width: 5%;
    min-width: 1rem;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0;
}

.list-operation-control-icon-container {
    margin-top: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
}

.list-operation-control-icon {
    width: 2.5rem;
    min-width: 2.5rem;
    align-items: center;
    border-style: none;
    margin: 0 2rem 0 2rem;
}

.list-operation-control-icon:hover {
    cursor: pointer;
}

.list-operation-tab-row {
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 0;
    margin-bottom: 0;
}

.list-operation-tab {
    display: flex;
    width: 22%;
    min-width: 6.5rem;
    max-width: 16rem;
    aspect-ratio: 4/1;
    text-align: center;
    justify-content: space-around;
    padding: 0.75rem 0.5rem 0.5rem 0.5rem;
    color: white;
    font-size: calc(10pt + 1vw);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.list-operation-tab:hover {
    cursor: pointer;
}

.list-operation-tab-text {
    align-self: center;
}

.list-operation-action-container {
    display: flex;
    flex-direction: row;
    flex-wrap: none;
    background-color: transparent;
    width: 90%;
    border-style: solid;
    border-width: 1rem 0.25rem 0.25rem 0.25rem;
    border-radius: 0rem;
    padding: 1rem;
}

.list-operation-name-container {
    width: 100%;
    padding: 0 10% 0 10%;
}

.list-operation-name-label {
    text-transform: uppercase;
    color: var(--dark-blue);
    padding-left: 0.5rem;
}

.list-operation-name-box {
    height: auto;
    width: 100%;
    border: solid thin var(--dark-blue);
    margin: 0;
    padding: 10px;
}

.list-operation-name-box:focus {
    border: solid thin var(--dark-orange);
    box-shadow: none;
}

.list-operation-add-words-container {
    width: 100%;
    padding: 1rem 7% 0 7%;
}

.list-operation-add-words-search-text-area {
    margin-left: 2rem;
}

.list-operation-add-words-search-text-label {
    text-transform: uppercase;
    color: var(--dark-blue);
    padding-left: 0.5rem;
}

.list-operation-add-words-search-text-box {
    height: auto;
    width: 70%;
    border: solid thin var(--dark-blue);
    padding: 10px;
}

.list-operation-add-words-search-text-box:focus {
    border: solid thin var(--dark-orange);
    box-shadow: none;
}

.list-operation-remove-words-container {
    width: 100%;
    padding: 1rem 7% 0 7%;
}

.list-operation-confirm-container {
    width: 100%;
    padding: 1rem 10% 0 10%;
}

.list-operation-words-container {
    background-color: lightgrey;
    border-style: none;
    border-radius: 0.5em;
    margin: 0 0 2rem 0;
    padding: 2rem 3rem 2rem 3rem;
}
