.tic-tac-toe-title {
    width: 80%;
    justify-content: center;
    text-align: center;
    color: white;
    background-color: var(--light-green);
    font-size: 200%;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    margin: auto;
    padding: 0.5rem 0.5rem 0.3rem 0.5rem;
}

.tic-tac-toe-board-container {
    width: 80%;
    background-color: var(--light-grey);
    text-align: center;
    padding: 1.5rem 15% 1.5rem 15%;
    border-style: solid;
    border-color: var(--light-green);
    border-width: 0.25rem;
}

.tic-tac-toe-square {
    width: 100%;
    margin: 0 0 0 0;
    padding: 0.5rem;
}

.tic-tac-toe-square-top-row {
    border-top: none;
    border-bottom: solid 0.25rem;
    border-color: grey;
}

.tic-tac-toe-square-bottom-row {
    border-top: solid 0.25rem;
    border-bottom: none;
    border-color: grey;
}

.tic-tac-toe-square-interior-row {
    border-top: solid 0.25rem;
    border-bottom: solid 0.25rem;
    border-color: grey;
}

.tic-tac-toe-square-left-column {
    border-left: none;
    border-right: solid 0.25rem;
    border-color: grey;
}

.tic-tac-toe-square-right-column {
    border-left: solid 0.25rem;
    border-right: none;
    border-color: grey;
}

.tic-tac-toe-square-interior-column {
    border-left: solid 0.25rem;
    border-right: solid 0.25rem;
    border-color: grey;
}

.tic-tac-toe-card {
    width: 100%;
    height: 100%;
}

.tic-tac-toe-card-image-div {
    display: flex;
    aspect-ratio: 1/1;
    width: 100%;
    height: 100%;
    align-items: center;
    overflow: hidden;
}

.tic-tac-toe-card-image {
    display: block;
    align-items: center;
    overflow: hidden;
    width: 85%;
    margin: 0;
    padding-top: 5%;
}

.tic-tac-toe-card-text {
    position: absolute;
    bottom: 4%;
    width: 100%;
    font-size: 130%;
}

.tic-tac-toe-xo-image {
    width: 100%;
    margin: 0;
}
