.start-page-div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0.5rem 2rem 0rem 2rem;
}

.start-page-control-container {
    width: 5%;
    min-width: 1rem;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0;
}

.start-page-control-icon-container {
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
}

.start-page-control-icon {
    width: 2.5rem;
    min-width: 2.5rem;
    align-items: center;
    border-style: none;
    margin: 0 2rem 0 2rem;
}

.start-page-control-icon:hover {
    cursor: pointer;
}

.start-page-tab-row {
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 0;
    margin-bottom: 0;
}

.start-page-tab {
    width: 20%;
    min-width: 8rem;
    max-width: 13rem;
    aspect-ratio: 4/1;
    text-align: center;
    padding: 0.75rem 0 0.5rem 0;
    color: white;
    font-size: calc(10pt + 1vw);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.start-page-tab:hover {
    cursor: pointer;
}

.start-page-setup-container {
    background-color: transparent;
    width: 90%;
    border-style: solid;
    border-width: 1rem 0.25rem 0.25rem 0.25rem;
    border-radius: 0rem;
    padding: 1rem;
}

.start-page-action-container {
    background-color: transparent;
    width: 90%;
    border-style: solid;
    border-width: 0.1rem 0.25rem 0.25rem 0.25rem;
    border-radius: 0rem;
    padding: 0.5rem 1rem 0.25rem 1rem;
}

.start-page-words-container {
    background-color: lightgrey;
    border-style: none;
    border-radius: 0.5em;
    padding: 2rem 3rem 2rem 3rem;
}
