.signup-button,
.signup-button:focus,
.signup-button:active,
.signup-button:active:focus {
    color: white;
    background-color: var(--dark-orange);
    font-size: 1.5rem;
    border-color: var(--dark-aqua);
    border-radius: 5rem !important;
    border-width: 0.1rem;
    padding: 0.75rem 2rem;
}

.signup-button:hover {
    background-color: var(--dark-aqua) !important;
    border-color: var(--dark-orange);
}

.panel-image-column {
    margin-bottom: 2rem;
    padding-left: 0;
    padding-right: 0;
}

.pricing-card {
    width: 14rem;
    padding-top: 1.5rem;
}

.trial-plan-button,
.trial-plan-button:focus,
.trial-plan-button:active,
.trial-plan-button:active:focus {
    border-radius: 0 !important;
    border-width: 0.1rem;
}

.monthly-trial-plan-button
.monthly-trial-plan-button:focus {
    color: white;
    background-color: var(--dark-blue);
    border-color: var(--dark-blue);
}

.monthly-trial-plan-button:hover {
    border-color: var(--dark-orange);
}

.annual-trial-plan-button,
.annual-trial-plan-button:focus {
    color: white;
    background-color: var(--dark-orange);
    border-color: var(--dark-orange) !important;
}

.annual-trial-plan-button:hover {
    background-color: var(--dark-orange) !important;
    border-color: var(--dark-blue) !important;
}
