.flashcards-level-tab-row {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
}

.flashcards-board-container {
    background-color: var(--light-grey);
    border-style: solid;
    border-width: 1rem 0.25rem 0.25rem 0.25rem;
    border-radius: 0rem;
    padding: 1rem;
}

.flashcards-control-container {
    width: 20%;
    min-width: 1rem;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0;
}

.flashcards-exit-icon-container {
    margin-top: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
}
