.flashcards-level-tab {
    position: relative;
    width: 40%;
    min-width: 3rem;
    padding: 0rem;
}

.flashcards-level-tab:hover {
    cursor: pointer;
}

.flashcards-level-tab-image {
    background-color: transparent;
    width: 100%;
}

.flashcards-level-tab-text {
    position: absolute;
    background-color: transparent;
    color:white;
    text-align: center;
    left: 10%;
    right: 10%;
    bottom: 15%;
    border-style: none;
    font-size: 2.25vw;
}

.flashcard {
    border: solid thin grey;
    width: 75%;
    min-width: 3rem;
}

.flashcard-image-div {
    display: flex;
    min-width: 3rem;
    aspect-ratio: 1/1;
    align-items: center;
    overflow: hidden;
    margin-top: 0;
    padding-top: 0;
}

.flashcard-image {
    display: block;
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
}

.flashcard-phrase {
    width: 90%;
    text-align: center;
}

.flashcard-fwd-rev-image {
    width: 40%;
    min-width: 2rem;
    align-items: center;
    border-style: none;
}

.flashcard-fwd-rev-image:hover {
    cursor: pointer;
}

.flashcard-target-sound-and-position-change-text {
    align-content: center;
    color: var(--dark-blue);
}

.flashcard-target-sound-and-position-change-text:hover {
    cursor: pointer;
}
